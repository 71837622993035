'use strict';
const _ = require('lodash');
const workerUtils = require('../workerUtils');
const {bi, fedops, ACTION_NAMES} = require('../../utils/loggingUtils');
const {CSRF_TOKEN} = require('../../constants/store');

function createInitHandler({store}) {
  function getControllersData({ controllerConfigs, app, sdk }) {
    return Object.keys(controllerConfigs).map(controllerId => {
      const compId = controllerConfigs[controllerId].compId;
      const type = controllerConfigs[controllerId].controllerData.controllerType;
      const config = controllerConfigs[controllerId].controllerData.settings;
      const connections = controllerConfigs[controllerId].connections || [];
      const updateControllerData = _.isFunction(sdk.__INTERNAL__.updateControllerData) ? sdk.__INTERNAL__.updateControllerData.bind(null, controllerId) : null;
      let warmupData = sdk.__INTERNAL__.getWarmupData && sdk.__INTERNAL__.getWarmupData(controllerId);
      warmupData = !self.isPseudoWorker ? workerUtils.decodeDates(warmupData) : warmupData;
      const { appParams, platformAPIs, wixCodeApi } = app;

      return {
        compId,
        type,
        config,
        connections,
        $w: sdk.getSelector(controllerId),
        warmupData,
        setProps: updateControllerData,
        appParams,
        platformAPIs,
        wixCodeApi,
        csrfToken: store.getValue(CSRF_TOKEN)
      };
    });
  }

  function registerControllerEvents(sdk, controllerId, configs) { //TODO: Add context
    configs[controllerId].controllerBehaviors
      .filter(item => item.behavior)
      .forEach(function(item) {
        const fn = sdk.__INTERNAL__.getEventHandler(item.behavior.params.callbackId);
        if (fn) {
          sdk.emitter.on(controllerId, item.action.name, fn);
        }
      });
  }

  function disposeOldControllers(app, livePreviewMode) {
    if (livePreviewMode) {
      const wrappedControllers = _.values(app.controllers).map(controller =>
        Promise.resolve(controller.dispose && controller.dispose())
      );
      return Promise.all(wrappedControllers);
    }
    return Promise.resolve();
  }

  function getControllersReadyResolver(app) {
    let controllersReady;
    app.controllersReady = new Promise(resolve => {
      controllersReady = resolve;
    });
    return controllersReady;
  }

  function callAppCreateControllers({ app, controllerConfigs, workerId, sdk }, reportError) {
    const controllerIds = Object.keys(controllerConfigs);
    if (!controllerIds.length) {
      return Promise.resolve([]);
    }
    let controllers = [];
    try {
      const createControllerArguments = getControllersData({ controllerConfigs, app, workerId, sdk });
      app.controllers = controllers = app.module.createControllers(createControllerArguments, app.controllerScriptMap);
    } catch (err) {
      reportError()(err);
    }
    const wrappedControllers = controllers.map(function(controller, index) {
      return Promise.resolve(controller).catch(reportError(controllerIds[index]));
    });
    return Promise.all(wrappedControllers);
  }

  function getControllersOnReady(controllers, sdk, controllerConfigs, app, workerId) {
    const controllerIds = Object.keys(controllerConfigs);
    app.controllers = controllerIds.reduce(function(acc, controllerId, index) {
      const compId = controllerConfigs[controllerId].compId;
      if (controllers[index]) {
        acc[compId] = controllers[index];
      }
      return acc;
    }, {});
    controllerIds.forEach(controllerId => registerControllerEvents(sdk, controllerId, controllerConfigs));
    return controllers.map((controller, index) => {
      const controllerId = controllerIds[index];
      const $w = sdk.getSelector(controllerId);
      const scopedGlobalSdkApis = sdk.getScopedGlobalApis(app.appDefId);
      return {
        controllerId,
        dependencies: controllerConfigs[controllerId].dependencies || [],
        onReady: $w.onReady.bind($w, function() {
          if (!controller) {
            return Promise.resolve();
          }
          if (controller.pageReady) {
            const appId = app.appDefId;
            const widgetId = _.get(controllerConfigs, `${controllerId}.controllerData.controllerType`);
            const {reportAppLoadingPhaseStart, reportAppLoadingPhaseFinish} = fedops.getAppLoadingPhaseReportFunctions({name: ACTION_NAMES.CONTROLLER_PAGE_READY, details: widgetId, params: {widgetId, appId}});
            reportAppLoadingPhaseStart();
            const beforePageReady = Date.now();
            return Promise
              .resolve(controller.pageReady($w, scopedGlobalSdkApis))
              .then(warmupData => {
                const parsedWarmupData = self.isPseudoWorker ? workerUtils.encodeDates(warmupData) : warmupData;
                sdk.__INTERNAL__.setWarmupData(controllerId, parsedWarmupData);
                reportAppLoadingPhaseFinish();
              })
              .catch(function(err) {
                bi.reportPlatformRenderError({
                  appId,
                  pageId: workerId,
                  widgetId,
                  error: err && err.message,
                  name: ACTION_NAMES.PAGE_READY_FAILED,
                  duration: Date.now() - beforePageReady
                });
              });
          }
          console.warn('controller.start is deprecated please export controller.pageReady method instead'); // eslint-disable-line no-console
          return controller.start($w);
        })
      };
    });
  }

  function callControllersReadyInOrder(controllersOnReady) {
    function visit(node) {
      if (node.entered || node.visited) {
        return;
      }
      node.entered = true;
      node.dependencies.forEach(controllerId => {
        const dep = _.find(controllersOnReady, { controllerId });
        if (dep) {
          visit(dep);
        }
      });
      node.visited = true;
      node.onReady();
    }
    let nextNode = _.find(controllersOnReady, node => !node.visited);
    while (nextNode) {
      visit(nextNode);
      nextNode = _.find(controllersOnReady, node => !node.visited);
    }
  }

  function handleInit({ apps, livePreviewMode }, { workerId, getApp, sdk }) {
    if (!apps) {
      throw new Error('Init message data must include apps property');
    }
    if (livePreviewMode && sdk.__INTERNAL__.clearReadyManager) {
      sdk.__INTERNAL__.clearReadyManager();
    }
    const controllersOnReady = [];
    return Promise.all(_.keys(apps)
      .map(appId => {
        const app = getApp(appId);
        if (!app) {
          bi.reportPlatformRenderError({
            name: ACTION_NAMES.HANDLE_INIT_FAILED,
            appId,
            error: `App with the id - ${appId} was not found in the app store`,
            pageId: workerId
          });
          return Promise.resolve();
        }
        const { controllers: controllerConfigs = {} } = apps[appId];
        const controllersReady = getControllersReadyResolver(app);
        const widgetArray = _.keys(controllerConfigs).map(cId => controllerConfigs[cId].controllerData.controllerType).join(',');
        let beforeInit;
        const {reportAppLoadingPhaseStart, reportAppLoadingPhaseFinish} = fedops.getAppLoadingPhaseReportFunctions({name: ACTION_NAMES.CREATE_CONTROLLERS, params: {appId: app.appDefId, widgetArray}});
        return disposeOldControllers(app, livePreviewMode)
          .then(() => app.initAppForPageResult)
          .then(() => {
            reportAppLoadingPhaseStart();
            beforeInit = Date.now();
            const reportError = controllerId => err => {
              const params = {
                appId,
                pageId: workerId,
                error: err && err.message,
                duration: Date.now() - beforeInit,
                name: ACTION_NAMES.CREATE_CONTROLLERS_FAILED
              };
              if (controllerId) {
                params.controllerId = controllerId;
              }
              bi.reportPlatformRenderError(params);
            };
            return callAppCreateControllers({ app, controllerConfigs, workerId, sdk }, reportError);
          })
          .then(function(controllers) {
            reportAppLoadingPhaseFinish();
            controllersReady();
            controllersOnReady.push(...getControllersOnReady(controllers, sdk, controllerConfigs, app, workerId));
          })
          .catch(e => {
            /*eslint-disable no-console*/
            console.error(e);
            controllersReady();
          });
      }))
      .then(() => callControllersReadyInOrder(controllersOnReady));
  }

  return handleInit;
}

module.exports = createInitHandler;
