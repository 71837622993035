'use strict';

const workerUtils = require('./workerUtils');
const scriptsValidator = require('./scriptsValidator');
const scriptsHandler = require('./scriptHandler');
const {fedops, ACTION_NAMES} = require('../utils/loggingUtils');

function loadUserGeneratedApps({applications = []} = {}, appsStore) {
  const {reportAppLoadingPhaseStart, reportAppLoadingPhaseFinish} = fedops.getAppLoadingPhaseReportFunctions({name: ACTION_NAMES.USER_GENERATED_APPS_LOADED});
  reportAppLoadingPhaseStart();

  scriptsValidator.validate(applications, ['id', 'url']);

  workerUtils.importModules(applications, appsStore, scriptsHandler, true);

  reportAppLoadingPhaseFinish();
}

module.exports = {
  loadUserGeneratedApps
};
