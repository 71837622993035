'use strict';

const platformUtils = require('santa-platform-utils');
const _ = require('lodash');
const constants = require('../../constants/constants');
const experiments = require('../../stores/selectors/experiments');
const wixCodeMessageType = require('../../wixCodeMessageTypes');
const {fedops} = require('../../utils/loggingUtils');

function createStartHandler({store, messageService}) {

  function createRemoteModelInterface(context, apps) {
    const RMI = new platformUtils.RemoteModelInterface(context);
    apps.forEach(function(app) {
      if (_.get(app, 'module.exports')) {
        RMI.setAppPublicAPI(app.appDefId, app.module.exports);
      }
      Object.keys(app.controllers).forEach(function(controllerId) {
        RMI.setPublicAPI(controllerId, app.controllers[controllerId].exports);
      });
    });

    return RMI;
  }

  function initSdk(messageData, RMI, sdk) {
    sdk.__INTERNAL__.initModel({ RMI, componentsHooks: platformUtils.componentsHooks }, messageData.id);
    if (_.isFunction(sdk.__INTERNAL__.addAppStudioGlobalsIfNeeded)) {
      sdk.__INTERNAL__.addAppStudioGlobalsIfNeeded(messageData.id);
    }
    self.$w = sdk.getSelector(messageData.id);
    self.ga = sdk.ga;
  }

  function exposePublicAPI({ module: appModule = {}, appDefId } = {}, workerId) {
    if (self.pmrpc && appModule.exports && !_.isEmpty(appModule.exports)) {
      self.pmrpc.api.set(`${wixCodeMessageType.PLATFORM_PUBLIC_API_PREFIX}${appDefId}_${workerId}`, appModule.exports);
      messageService.sendMessage({
        type: wixCodeMessageType.REQUEST_API,
        intent: wixCodeMessageType.WIX_CODE_INTENT,
        appDefId,
        workerId
      });
    }
  }

  function handleStart(messageData, { workerId, getAllApps, env, sdk }) {
    if (!messageData.id) {
      throw new Error('Could not init sdk: `context.id` is missing');
    }
    if (!messageData.context) {
      throw new Error('Could not init sdk: `context.context` is missing');
    }

    if (!self.pmrpc && self.importScripts) {
      self.importScripts(constants.PM_RPC);
    }

    const allApps = _.isEmpty(messageData.apps) ?
      getAllApps() :
      getAllApps().filter(app => _.includes(messageData.apps, app.appDefId));
    if (env !== 'backend') {
      allApps.forEach(app => exposePublicAPI(app, workerId));
    }
    const allReady = allApps.map(function(app) {
      app.controllersReady = app.controllersReady && app.controllersReady.then(function() {
        return Promise.resolve();
      });
      return Promise.resolve(app.controllersReady);
    });

    return Promise.all(allReady)
      .then(function() {
        const RMI = createRemoteModelInterface(messageData.context, getAllApps());
        if (sdk) {
          initSdk(messageData, RMI, sdk);
          sdk.__INTERNAL__.triggerOnReady(function() {
            if (experiments.isExperimentOpen(store, 'sv_handleFailingWixCodeSdk')) {
              messageService.sendWidgetStatusChangedMessage(messageData.id, 'success');
            } else {
              messageService.sendWidgetReadyMessage(messageData.id);
            }

            if (_.get(self, 'performance.getEntriesByType')) {
              const performanceMetrics = self.performance.getEntriesByType('measure');
              messageService.sendPerformanceMetricsMessage(workerId, JSON.stringify(performanceMetrics));
            }
            fedops.reportAppLoaded();
          });
        } else if (experiments.isExperimentOpen(store, 'sv_handleFailingWixCodeSdk')) {
          messageService.sendWidgetStatusChangedMessage(messageData.id, 'failed');
        }
      });
  }

  return handleStart;
}

module.exports = createStartHandler;
